<template>
  <v-data-table
      dense
      :headers="headers"
      :items="users"
      :options.sync="options"
      :server-items-length="totalUsers"
      :footer-props="footerProps"
      :items-per-page="50"
      :loading="loading"
      item-key="id"
      :search="search"
      v-model="selected"
      class="elevation-1"
  >
    <template #top>
      <v-toolbar
          id="toolbar"
          flat
      >
        <v-text-field
            v-model="search"
            dense
            prepend-inner-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            clearable
        />
        <v-text-field
            v-model="id"
            dense
            prepend-inner-icon="mdi-account-search"
            label="Search by ID"
            single-line
            hide-details
            clearable
            class="ml-2"
            style="max-width: 200px"
        />
        <export-users/>
      </v-toolbar>
      <v-dialog
          v-model="dialog"
          width="500"
      >
        <v-card>
          <v-card-text class="pa-4">
            <v-form class="text-center" @submit.prevent="updatePassword">
              <v-text-field label="New password" type="password" v-model="password"/>
              <v-btn type="submit" :disabled="password.length < 6" rounded color="primary">Update</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:item.created_at="{item}">
      <div>{{ item.created_at | diffForHumans }}</div>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn
          color="primary lighten-2 mr-2"
          small
          icon
          @click="editItem(item)"
      >
        <v-icon
            small
        >
          mdi-pencil
        </v-icon>
      </v-btn>
    </template>
    <template v-slot:no-data>
      <v-btn
          color="primary"
          @click="initialize"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>>

<script>
import axios from "axios"
import moment from "moment"
import ExportUsers from "./ExportUsers";
import Swal from "sweetalert2";

export default {
  name: 'Users',
  components: {ExportUsers},
  data: () => ({
    password: '',
    users: [],
    selected: [],
    footerProps: {
      itemsPerPageOptions: [20, 50, 100, 500, 5000, 10000]
    },
    search: '',
    id: '',
    searchTimeout: null,
    page: 1,
    pageCount: 0,
    totalUsers: 0,
    options: {},
    loading: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'ID',
        value: 'id',
      },
      {
        text: 'Name',
        align: 'start',
        value: 'name',
      },
      {text: 'Phone', value: 'phone'},
      {text: 'Registered at', value: 'created_at'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    selectedItemForDelete: '',
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    selectedIds() {
      return this.selected.map(a => a.id)
    },
    searchTerm() {
      return this.search ? '&query=' + this.search : ''
    },
    idTerm() {
      return this.id ? '&id=' + this.id : ''
    }
  },

  watch: {
    options: {
      handler() {
        this.initialize()
      },
      deep: true,
    },
    search() {
      this.id = ''
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      this.searchTimeout = setTimeout(() => {
        this.initialize()
      }, 300)
    },
    id() {
      this.search = ''
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      this.searchTimeout = setTimeout(() => {
        this.initialize()
      }, 300)
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
  },

  methods: {
    initialize() {
      this.loading = true
      let link = 'admin/users?page=' + this.options.page + this.searchTerm + '&per_page=' + this.options.itemsPerPage + this.idTerm
      axios.get(link).then((response) => {
        this.users = response.data.data
        this.totalUsers = response.data.total
        this.loading = false
      })
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    updatePassword(){
      const formData = {
        password: this.password
      }
      const url = 'user/'+this.editedItem.id
      axios.put(url, formData).then(()=>{
        Swal.fire('Password updated successfully')
        this.dialog = false
      }).catch(()=>{
        this.dialog = false
        Swal.fire('Operation failed, please try again.','','warning')
      })
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.selectedItemForDelete = item;
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      // this.users.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.password = ''
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem)
      } else {
        this.users.push(this.editedItem)
      }
      this.close()
    },
  },
  filters: {
    diffForHumans(value) {
      return moment(value).calendar()
    }
  }
}
</script>

<style scoped>

</style>
